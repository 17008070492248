<template>
  <createClinic
    backRoute="SuperAdminClinic"
    :franchiseId="franchiseId"
    v-if="franchiseId"
  />
</template>

<script>

  import createClinic from '../../components/clinics/createClinic.vue'

  export default {
    name:'CreateFranchiseClinic',
    components: {
      createClinic
    },
    data:()=>({
       franchiseId:''
    }),
    created() {
      this.franchiseId =  this.$route.params.franchiseId;
    }
  }
</script>
